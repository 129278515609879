<template>
  <div class="dash-table dash-activities">
    <h2>Atividades</h2>
    <div class="activities-wrap">
      <div v-for="activity in activities" :key="activity.id" class="activity">
        <!-- {{ activity }} -->
        <p v-html="activityFormattedText(activity)"></p>
        <div class="date-time">
          <span>{{ $formattedDateWithoutHours(activity.attributes.updatedAt) }}</span>
          <span>{{ $formattedDateOnlyHours(activity.attributes.updatedAt) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['activities'],
  methods: {
    activityFormattedText (activity) {
      switch (activity.attributes.action) {
        case 'order.updated_status': {
          const orderId = activity.attributes.log.orderId
          const userName = activity.attributes.log.userName
          const status = activity.attributes.log.status
          let statusMessage
          switch (status) {
            case 'sent':
              statusMessage = 'aceito'
              break
            case 'failed':
              statusMessage = 'cancelado'
              break
            case 'requested':
              statusMessage = 'solicitado'
              break
            default:
              statusMessage = status
          }
          return `O pedido <strong>#${orderId}</strong> do usuário ${userName} teve status alterado para <strong>${statusMessage}</strong>.`
        }
        case 'invoice.updated_status': {
          const status = activity.attributes.log.status
          let statusMessage
          switch (status) {
            case 'approved':
              statusMessage = 'Reabilitada'
              break
            case 'rejected':
              statusMessage = 'Cancelada'
              break
            default:
              statusMessage = status
          }
          return `A nota <strong>${activity.attributes.log.code}</strong> do usuário ${activity.attributes.log.userName} teve status alterado para <strong>${statusMessage}</strong>.`
        }
        case 'invoice.created':
          return `A nota <strong>${activity.attributes.log.code}</strong> foi adicionada pelo usuário ${activity.attributes.log.userName}.`
        case 'compensation.synced': {
          const amount = (activity.attributes.log.amount / 100).toFixed(2).replace('.', ',')
          if (activity.attributes.log.amount > 0) {
            return `Uma compensação de <strong>R$ ${amount}</strong> foi criada para o usuário <strong>${activity.attributes.log.userName}</strong>.`
          } else {
            const negativeAmount = (-activity.attributes.log.amount / 100).toFixed(2).replace('.', ',')
            return `Nova compensação de <strong>- R$ ${negativeAmount}</strong> para o usuário <strong>${activity.attributes.log.userName}</strong>.`
          }
        }
        case 'sale.available':
          return `A promoção ${activity.attributes.log.name} foi registrada oferecendo ${activity.attributes.log.discount * 100}% de desconto.`
        case 'user.updated_current_discount':
          return `O desconto adicional do usuário ${activity.attributes.log.userName} foi alterado para ${activity.attributes.log.currentDiscount * 100}%.`
        default:
          return activity.attributes.action
      }
    }
  }
}

</script>
