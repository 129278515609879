<template>
  <div>
    <div v-if="order" class="modal ">
      <div class="modal-content popup-detail see-order">
        <span class="close small-btn close" @click="closeModal">&times;</span>
        <div class="title-wrap">
          <h1>Pedido #{{ order.id }}!</h1>
          <!--
            order.relationships.items.data[0]
            order.relationships.items.data[0].relationships.price.id
          -->
          <!--<div>Teste {{ order_data }}</div>-->
          <div class="status-wrap" :class="order.attributes.status">
            <span>
              <template v-if="order.attributes.status == 'requested'">
                Solicitado
              </template>
              <template v-if="order.attributes.status == 'draft'">
                Rascunho
              </template>
              <template v-if="order.attributes.status == 'sent'">
                Aceito
              </template>
              <template v-if="order.attributes.status == 'completed'">
                Concluído
              </template>
              <template v-if="order.attributes.status == 'cancelled'">
                Cancelado
              </template>
              <template v-if="order.attributes.status == 'failed'">
                Enviando
              </template>
            </span>
          </div>
        </div>
        <div class="order-details details mounted">
          <div v-if="order.attributes.status !== 'draft'" class="order_note" :class="order.attributes.status">
            <template v-if="order.attributes.status == 'requested'">
              Seu pedido foi solicitado e deve ser aceito pela Antares em breve.
            </template>
            <template v-if="order.attributes.status == 'sent' || order.attributes.status == 'approved'">
              Seu pedido foi aceito pela Antares e está sendo encaminhado internamente.
            </template>
            <template v-if="order.attributes.status == 'completed' || order.attributes.status == 'finished'">
              Seu pedido está concluído! Não há nada mais a ser feito com ele.
            </template>
            <template v-if="order.attributes.status == 'active'">
              Este é um pedido ativo.
            </template>
            <template v-if="order.attributes.status == 'onroute'">
              Seu pedido está a caminho e deve chegar em breve.
            </template>
            <template v-if="order.attributes.status == 'cancelled' || order.attributes.status == 'rejected' || order.attributes.status == 'blocked' || order.attributes.status == 'inactive'">
              Seu pedido foi cancelado pelos sistemas Antares. Entre em contato para obter mais detalhes.
            </template>
            <template v-if="order.attributes.status == 'failed'">
              Seu pedido foi cancelado ou não pôde ser enviado para a Antares. Entre em contato para obter mais detalhes.
            </template>
            <template v-if="order.attributes.status == 'pending'">
              Seu pedido está sendo enviado e deve chegar aos sistemas da Antares em breve.
            </template>
          </div>
          <div class="number-shop details-flex">
            <div class="description">
              <p>Descrição</p>
              <span v-if="order.attributes.description">{{ order.attributes.description }}</span>
              <span v-else>Pedido sem descrição</span>
            </div>
            <div class="number" v-if="order.attributes.codeErp">
              <p>Código ERP </p>
              <span>{{ order.attributes.codeErp }}</span>
            </div>
            <div class="shop">
              <p>Loja</p>
              <span>{{ order.relationships.store.attributes.name }} ({{ order.relationships.store.attributes.cnpj }})</span>
            </div>
          </div>
        </div>
        <div class="popup-table itens-table detail">
          <table>
            <thead>
              <tr>
                <th>
                  <p>Qtd</p>
                </th>
                <th>
                  <p>Itens</p>
                </th>
                <th>
                  <p>Preço</p>
                </th>
                <th>
                  <p>Entrega</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items.data" v-bind:key="item.id">
                <td class="qtd">
                  <p>{{ item.attributes.quantity }}</p>
                </td>
                <td>
                  <p>{{ item.relationships.item.attributes.name }}</p>
                </td>
                <td>
                  <div class="tooltip-text-wrapper price">
                    <!-- <p v-if="item.hunt"><s>R$ {{ $formatCentsToCurrency(item.attributes.originalAmount) }}</s></p> -->
                    <!-- <p>R$ {{ $formatCentsToCurrency(item.attributes.amount) }}</p> -->
                    <!--
                    <p v-if="item.attributes.unitOriginalPrice !== item.attributes.unitDiscountPrice">
                      <s>
                        R$ {{$formatCentsToCurrency(item.attributes.unitOriginalPrice) }}
                      </s>
                    </p>
                    -->
                    <p>R$ {{ $formatCentsToCurrency(item.attributes.unitDiscountPrice * item.attributes.quantity) }}<span class="price-sum">R$ {{ $formatCentsToCurrency(item.attributes.unitDiscountPrice) }}/un.</span></p>
                    <!--
                    <div class="tooltip">
                      <span class="tooltip-text">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laborum dicta
                        enim quas odio vel accusamus perspiciatis voluptatibus sint at ex. Natus, necessitatibus harum.
                        Quaerat alias velit, explicabo natus consequatur numquam.
                        <div class="arrow"></div>
                      </span>
                    </div>
                    -->
                  </div>
                </td>
                <td>
                  <p>{{ formatDate(item.attributes.deliveryAt) }}</p>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="empty-order">Esse pedido ainda não possui itens adicionados.</div>
        </div>
        <div class="payment-detail">
          <div class="discount-wrap">
            <div class="used-value">
              <div class="value-wrap">
                <p>
                  R$ {{ $formatCentsToCurrency(order_data.used_balance) }}
                </p>
                <span>
                  utilizado da caçada
                </span>
              </div>
            </div>
            <div class="current-discount">
              <p>Desconto atual</p>
              <span>{{ user.attributes.discounts.current * 100}}% OFF</span>
            </div>
          </div>
          <div class="total-wrap">

            <div class="total sub">
              <p>Valor dos itens</p>
              <div>
                <h3>R$ {{ $formatCentsToCurrency(orderAmount()) }}</h3>
              </div>
            </div>
            <div class="total sub discount">
              <p>Desconto da caçada</p>
              <div>
                <h3>- R$ {{ $formatCentsToCurrency(order_data.used_balance) }}</h3>
              </div>
            </div>
            <div class="total">
              <p>Total</p>
              <div>
                <h3>R$ {{ $formatCentsToCurrency(orderAmountlWithDiscount()) }}</h3>
              </div>
            </div>
          </div>
        </div>

        <button v-if="order.attributes.status === 'draft'" @click="showEditOrderModal = true" class="main-btn edit-order">
          Editar pedido
        </button>

      </div>
    </div>
    <ModalOrderEdit
      :order_info="order"
      @loaded="closeModal"
      v-if="showEditOrderModal"
      @close="closeModal()"
      @close-edit-modal="handleEditModalClose"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '@/config/axiosConfig.js'
import ModalOrderEdit from './ModalOrderEdit.vue'
export default {
  props: ['order'],
  data () {
    return {
      items: [],
      store: [],
      order_data: [],
      showEditOrderModal: false
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    ...mapActions(['setLoadingState']),
    closeModal () {
      // console.log('closeModal acionado pela popup secundária')
      this.$emit('close')
      document.body.classList.remove('modal-opened')
      this.showEditOrderModal = false
      this.order_info = null
    },
    handleEditModalClose () {
      // console.log('Evento close-edit-modal recebido. Fechando ModalOrderDetail.')
      this.closeModal()
    },
    formatDate (date) {
      const formattedDate = new Date(date)
      formattedDate.setDate(formattedDate.getDate() + 1)
      return formattedDate.toLocaleDateString('pt-BR')
    },
    async fetchOrderDetails () {
      this.setLoadingState(true)
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/my/orders/${this.order.id}`)
        this.order_data = response.data.data
        this.items = response.data.data.relationships.items
        this.store = response.data.data.relationships.store
        this.calculateOrderUsedBalance()
      } catch (error) {
        console.log(error)
      } finally {
        this.setLoadingState(false)
      }
    },
    orderAmount () {
      if (this.items.data) {
        return this.items.data.reduce((total, item) => {
          const price = item.attributes.unitDiscountPrice
          return total + price * item.attributes.quantity
        }, 0)
      }
      return null
    },
    orderAmountlWithDiscount () {
      const orderAmount = this.orderAmount()
      return orderAmount - this.order_data.used_balance
    },
    // Aqui chamo o used_balance assim que o Jean liberar
    calculateOrderUsedBalance () {
      if (this.items.data) {
        const onlyHuntItems = this.items.data.filter(item => item.relationships.item.attributes.hunt === true)
        this.order_data.used_balance = (onlyHuntItems.reduce((total, item) => {
          return total + Math.round(item.attributes.subtotal * this.user.attributes.discounts.current)
        }, 0))
      }
      return null
    }
  },
  watch: {
    order: {
      immediate: true,
      handler (newOrder, oldOrder) {
        if (newOrder !== oldOrder) {
          this.fetchOrderDetails()
        }
      }
    }
  },
  components: { ModalOrderEdit }
}
</script>
