<template>
  <div v-if="order_info" class="modal">
    <div class="popup-detail edit-order itens data checkout">
      <span class="close small-btn" @click="closeModalEdit">&times;</span>
        <div class="title">
          <h1>Editar pedido@</h1>
        </div>
        <div class="order-data-form">
          <form id="form1" @submit.prevent="submitForm1" ref="form1">
            <div class="input-flex">
              <div class="input-wrap half-big">
                <label for="description">Descrição</label>
                <input name="description" v-model="order.attributes.description" id="description" placeholder="Digite aqui"  />
              </div>
            </div>
            <div style="display: none;">
              <input type="text" name="used_balance" :value="order.used_balance * -1">
              <input type="text" name="previous_balance" :value="user.attributes.availableBalance">
            </div>
          </form>
        </div>
        <div class="input-wrap">
          <label for="search">Item</label>
          <ItemsMultiSelect @item-selected="addItemToOrder" />
        </div>
        <div class="itens-table" >
          <table>
            <tr>
              <th>
                <p>Itens</p>
              </th>
              <th>
                <p>Preço</p>
              </th>
              <th>
                <p>Quantidade</p>
              </th>
              <th>
                <p>Entrega</p>
              </th>
            </tr>
            <tr v-for="item in order.relationships.items.data" :key="item.id">
              <td>
                <button class="round-btn delete" @click="removeItem(item)"></button>
                <p>{{ item.relationships.item.attributes.name }}</p>
              </td>
              <td>
                <div class="tooltip-text-wrapper price">
                  R$ {{ $formatCentsToCurrency(item.attributes.unitOriginalPrice) }}
                  <p>R$ {{ $formatCentsToCurrency(item.attributes.unitDiscountPrice * item.attributes.quantity) }}<span class="price-sum">R$ {{ $formatCentsToCurrency(item.attributes.unitDiscountPrice) }}/un.</span></p>

                  <div class="tooltip">
                    <span class="tooltip-text">
                      Você realiza o pedido com um desconto de partida de {{ Math.round(item.attributes.unitDiscountPrice * 100 / item.attributes.unitOriginalPrice) }}% em relação aos preços de tabela.
                      <div class="arrow"></div>
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <button class="round-btn less" @click="decrementItemQuantity(item)"></button>
                <p>{{ item.attributes.quantity }}</p>
                <!-- <button class="round-btn more" @click="incrementItemQuantity(item)" v-if="item.can_be_added"></button> -->
                <button class="round-btn more" @click="incrementItemQuantity(item)"></button>
              </td>
              <!--
              <td>
                <p>R${{ $formatCentsToCurrency((item.attributes.unitDiscountPrice * item.attributes.quantity)) }}</p>
              </td>
              -->
              <td class="arrive-date">
                <input type="date" :min="getMinDate()" v-model="item.attributes.deliveryAt"/>
                <!-- <p v-if="item.attributes.deliveryAt">{{ $formattedDateWithoutHours(item.attributes.deliveryAt) }}</p> -->
              </td>
            </tr>
          </table>
        </div>
        <div class="payment-detail">
          <div class="discount-wrap">
            <div class="used-value">
              <div class="value-wrap">
                <p>R$ {{ $formatCentsToCurrency(this.order.used_balance) }}</p>
                <span>utilizado de R$ {{ $formatCentsToCurrency(user.attributes.availableBalance) }}</span>
              </div>
            </div>
            <div class="current-discount">
              <p>Desconto atual</p>
              <span>{{ Math.round(user.attributes.discounts.current * 100) }}% OFF</span>
            </div>
          </div>
          <div class="total-wrap">
            <div class="total sub">
              <p>Valor dos itens</p>
              <div>
                <h3>R$ {{ $formatCentsToCurrency(calculateOrderAmount()) }}</h3>
              </div>
            </div>
            <div class="total sub discount">
              <p>Desconto da caçada</p>
              <div>
                <h3>- R$ {{ $formatCentsToCurrency(this.order.used_balance) }}</h3>
              </div>
            </div>
            <div class="total">
              <p>Total</p>
              <div>
                <h3>R$ {{ $formatCentsToCurrency(calculateOrderAmountWithDiscount()) }}</h3>
              </div>
            </div>
              <!-- <div> -->
               <!-- <span>R${{ $formatCentsToCurrency(calculateOrderAmount()) }}</span> -->
               <!-- <span>{{ calculateOrderAmount() }}</span> -->
               <!--   <h3>R${{ $formatCentsToCurrency(calculateOrderAmountWithDiscount()) }}</h3> -->
              <!-- </div> -->
          </div>
        </div>
        <div class="popup-buttons">
          <button class="main-btn checkout-order" @click="submitOrder('requested')" :class="{ 'disable': order.relationships.items.data == 0 }">
          Enviar pedido à Antares</button>
          <button class="main-link save-sketch" @click="submitOrder('draft')">Salvar como rascunho</button>
        </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '@/config/axiosConfig.js'
// import VueMultiselect from 'vue-multiselect'
import ItemsMultiSelect from '@/components/ItemsMultiselect.vue'

export default {
  props: ['order_info'],
  data () {
    return {
      step: 1,
      selectedItems: null,
      order: [],
      selectedStore: ''
    }
  },
  components: {
    ItemsMultiSelect
  },
  watch: {
    'order.relationships.items.data': {
      handler () {
        this.calculateOrderUsedBalance()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['user', 'items'])
  },
  methods: {
    ...mapActions(['setLoadingState', 'fetchOrders', 'fetchUser']),
    closeModalEdit () {
      // this.$emit('close')
      // console.log('Evento close-edit-modal emitido de ModalOrderEdit.')
      this.$emit('close-edit-modal')
    },
    formatMinDate (dateString) {
      const [year, month, day] = dateString.split('-')
      return `${day}/${month}/${year}`
    },
    async submitOrder (status) {
      this.order.status = status

      // Verifica se todas as datas de entrega são válidas
      const isValid = this.order.relationships.items.data.every(item => {
        const minDate = this.getMinDate()
        return item.attributes.deliveryAt >= minDate
      })

      if (!isValid) {
        const minDate = this.getMinDate()
        const minDateFormatted = this.formatMinDate(minDate)
        this.$notification.emit('flash', { message: `As datas de entrega precisam ser todas posteriores a ${minDateFormatted}.`, type: 'error' })
        return // Impede a continuação do processo se houver datas inválidas
      }

      try {
        await this.submitFormItems()
        await this.submitForm1()
        await this.fetchUser()
        // console.log('Dados atualizados do usuário:', this.user)
        this.$notification.emit('flash', { message: 'Pedido editado com sucesso!', type: 'success' })
        this.fetchOrders(1)
      } catch (e) {
        // console.log('Erro no submitformItems', e)
      } finally {
        await this.closeModalEdit()
      }
    },
    async fetchOrderData () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/my/orders/${this.order_info.id}`)
        this.order = response.data.data
      } catch (error) {
        this.$notification.emit('flash', { message: 'Erro ao editar o pedido', type: 'error' })
        console.error(error)
      }
    },
    checkOrderItemsOnEdit (orderItems) {
      const itemsArray = this.items.map(item => { return item.relationships.item.id })

      const response = orderItems.filter(orderItem => {
        if (orderItem.relationships.item && orderItem.relationships.item.id) {
          return itemsArray.includes(orderItem.relationships.item.id)
        } else {
          return false
        }
      })
      return this.formatOrderItems(response)
    },
    formatOrderItems (items) {
      items.forEach(item => {
        try {
          const response = axios.get(`${process.env.VUE_APP_API_URL}/api/my/items`, {
            params: {
              'filter[id]': item.relationships.item.id
            }
          })
          this.items.push(response.data.data)
        } catch (error) {
          console.log(error)
        } finally {

        }
      })

      return items.map(item => {
        const storeItem = this.items.find(storeItem => storeItem.relationships.item.id === item.relationships.item.id) // busca os dados do item no array de items do store
        return {
          id: item.relationships.item.id,
          name: item.relationships.item.attributes.name,
          can_be_added: item.attributes.enable_discount,
          quantity: item.attributes.quantity,
          hunt: item.relationships.item.attributes.hunt,
          delivery_at: item.attributes.deliveryAt,
          unit_original_price: storeItem.attributes.amount * (1 - this.user.attributes.discounts.main),
          unit_discount_price: (storeItem.attributes.amount * (1 - this.user.attributes.discounts.main)) * (1 - Number(this.user.attributes.discounts.current))
        }
      })
    },
    async submitForm1 () {
      this.setLoadingState(true)
      const form = this.$refs.form1
      const formData = new FormData(form)

      formData.append('code', this.order.attributes.code)
      formData.append('used_balance', String(Math.floor(this.order.used_balance * -1)))
      formData.append('store_id', this.user.relationships.stores.data[0].id)
      formData.append('status', this.order.status)
      formData.append('previous_balance', this.user.attributes.availableBalance)
      formData.delete('number')
      // // alert(JSON.stringify(formData))
      // for (const pair of formData.entries()) {
      //   console.log(pair[0], pair[1])
      // }
      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/api/my/orders/${this.order_info.id}`, formData)
        this.step = 2
      } catch (error) {
        console.log(error)
      } finally {
        this.setLoadingState(false)
      }
    },
    async submitFormItems () {
      this.setLoadingState(true)

      try {
        const formData = new FormData()

        this.order.relationships.items.data.forEach((item, index) => {
          formData.append(`items[${index}][id]`, item.relationships.item.id)
          formData.append(`items[${index}][quantity]`, item.attributes.quantity)
          formData.append(`items[${index}][unit_original_price]`, Math.round(item.attributes.unitOriginalPrice))
          formData.append(`items[${index}][unit_discount_price]`, item.relationships.item.attributes.hunt ? Math.round(item.attributes.unitDiscountPrice) : Math.round(item.attributes.unitOriginalPrice))
          formData.append(`items[${index}][subtotal]`, Math.round(item.attributes.subtotal))
          formData.append(`items[${index}][delivery_at]`, item.attributes.deliveryAt)
          formData.append(`items[${index}][price_id]`, this.user.relationships.price.id)
          // Campos novos
          // formData.append(`items[${index}][discount]`, 0.30);
          // formData.append(`items[${index}][debit]`, 1234);
        })

        await axios.post(`${process.env.VUE_APP_API_URL}/api/my/orders/${this.order_info.id}/items`, formData)
      } catch (error) {
        console.error(error)
        this.$notification.emit('flash', { message: 'Erro ao editar o pedido', type: 'error' })
      } finally {
        this.setLoadingState(false)
      }
    },
    async submitFinalForm () {
      this.setLoadingState(true)
      try {
        const formData = new FormData()
        formData.append('previous_balance', this.previous_balance)
        formData.append('used_balance', String(Math.floor(this.order.used_balance * -1)))
        // used_balance e previous_balance

        await axios.post(`${process.env.VUE_APP_API_URL}/api/my/orders/${this.order.id}`, formData)
        await this.fetchUser()
        // console.log('Dados atualizados do usuário:', this.user)
        this.$notification.emit('flash', { message: 'Pedido editado com sucesso', type: 'success' })
        this.$emit('close')
      } catch (error) {
        this.$notification.emit('flash', { message: 'Erro ao editar o pedido', type: 'error' })
      } finally {
        this.setLoadingState(false)
      }
    },
    addItemToOrder (originalItem) {
      const item = { ...originalItem }
      item.attributes.unitOriginalPrice = item.attributes.originalAmount
      item.attributes.unitDiscountPrice = item.attributes.amount
      // console.log('Valores atribuídos ao item:', {
      //   unitOriginalPrice: item.attributes.unitOriginalPrice,
      //   unitDiscountPrice: item.attributes.unitDiscountPrice,
      //   originalAmount: item.attributes.originalAmount,
      //   amount: item.attributes.amount
      // })
      // aqui
      item.attributes.deliveryAt = this.getMinDate()
      item.attributes.quantity = 1
      this.order.relationships.items.data.push(item)
    },
    removeItem (item) {
      const index = this.order.relationships.items.data.indexOf(item)
      // const index = this.order.items.indexOf(item)
      if (index !== -1) {
        this.order.relationships.items.data.splice(index, 1)
        this.order.items.splice(index, 1)
      }
    },
    incrementItemQuantity (item) {
      item.attributes.quantity++
      item.attributes.subtotal += item.attributes.unitDiscountPrice
    },
    decrementItemQuantity (item) {
      if (item.attributes.quantity > 1) {
        item.attributes.subtotal -= item.attributes.unitDiscountPrice
        item.attributes.quantity--
      }
    },
    calculateItemUnitDiscountPrice (item) {
      if (item.hunt) {
        // item.unit_discount_price = item.unit_original_price * (1 - this.user.attributes.discounts.current)
        return Math.round(item.attributes.originalAmount * (1 - Number(this.user.attributes.discounts.current)))
      }
      // item.unit_discount_price = item.unit_original_price
      return Math.round(item.attributes.originalAmount)
    },
    calculateOrderUsedBalance () {
      const onlyHuntItems = this.order.relationships.items.data.filter(item => item.relationships.item.attributes.hunt === true)
      console.table(onlyHuntItems)
      const totalUsedBalance = onlyHuntItems.reduce((total, item) => {
        item.attributes.subtotal = item.attributes.subtotal ? item.attributes.subtotal : item.attributes.unitDiscountPrice
        return total + (Math.round(item.attributes.subtotal * this.user.attributes.discounts.current))
      }, 0)

      const availableBalance = this.user.attributes.availableBalance

      if (totalUsedBalance > availableBalance) {
        this.order.used_balance = availableBalance
      } else {
        this.order.used_balance = totalUsedBalance
      }
    },
    // calcular
    // calculateOrderAmount () {
    //   return this.order.relationships.items.data.reduce((total, item) => {
    //     return total + item.attributes.subtotal
    //   }, 0)
    // },
    calculateOrderAmount () {
      return this.order.relationships.items.data.reduce((total, item) => {
        // Usando unitDiscountPrice em vez de unitOriginalPrice
        return total + (item.attributes.unitDiscountPrice * item.attributes.quantity)
      }, 0)
    },
    calculateOrderAmountWithDiscount () {
      const orderAmount = this.calculateOrderAmount()
      return orderAmount - this.order.used_balance
    },
    addBusinessDays (startDate, numDays) {
      const result = new Date(startDate.getTime())
      let businessDaysRemaining = numDays
      while (businessDaysRemaining > 0) {
        // Adiciona um dia à data atual
        result.setDate(result.getDate() + 1)
        // Se o dia atual for de segunda a sexta-feira, decrementa os dias úteis restantes
        if (result.getDay() !== 0 && result.getDay() !== 6) {
          businessDaysRemaining--
        }
      }
      return result
    },
    getMinDate () {
      const today = new Date()
      const minDate = this.addBusinessDays(today, 7) // Usando a função addBusinessDays definida anteriormente
      const year = minDate.getFullYear()
      const month = (minDate.getMonth() + 1).toString().padStart(2, '0')
      const day = minDate.getDate().toString().padStart(2, '0')
      return `${year}-${month}-${day}`
    }
  },
  created () {
    this.fetchOrderData()
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
