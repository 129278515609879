<template>
  <div class="dash-table dash-activities">
    <h2>Atividades</h2>
    <div class="activities-wrap">
      <div v-for="activity in activities" :key="activity.id" class="activity">
        <!-- {{ activity }} -->
        <p>{{ activityFormattedText(activity) }}</p>
        <p style="background-color: lime; display: none;">{{ activity }}</p>
        <div class="date-time">
          <span>{{ $formattedDateWithoutHours(activity.attributes.updatedAt) }}</span>
          <span>{{ $formattedDateOnlyHours(activity.attributes.updatedAt) }}</span>
        </div>
      </div>
    </div>
    <div class="act-empty" v-if="activities.length == 0">
      <p>
        Você ainda não tem atividades, mas suas atualizações aparecerão aqui à medida que forem acontecendo.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: ['activities'],
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    activityFormattedText (activity) {
      const formatNumber = (value) => {
        // Verifica se o valor é um número antes de aplicar a máscara
        const formattedValue = typeof value === 'number' ? value.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) : value

        // Adiciona o sinal de negativo se o valor for menor que zero
        const prefix = value < 0 ? '- ' : ''

        return `${prefix}R$ ${formattedValue.replace('-', '')}`
      }
      switch (activity.attributes.action) {
        case 'user.updated_current_discount':
          return `Seu desconto adicional foi atualizado para ${activity.attributes.log.currentDiscount * 100}%.`
        case 'sale.available':
          return `A promoção ${activity.attributes.log.name} foi registrada oferecendo ${activity.attributes.log.discount * 100}% de desconto.`
        case 'order.updated_status':
          switch (activity.attributes.log.status) {
            case 'sent':
              return `O pedido #${activity.attributes.log.orderId} teve seu status alterado para Aceito.`
            case 'requested':
              return `O pedido #${activity.attributes.log.orderId} teve seu status alterado para Solicitado.`
            case 'cancelled':
              return `O pedido #${activity.attributes.log.orderId} teve seu status alterado para Cancelado.`
            case 'failed':
              return `O pedido #${activity.attributes.log.orderId} teve seu status alterado para Enviando.`
            case 'draft':
              return `O pedido #${activity.attributes.log.orderId} teve seu status alterado para Rascunho.`
            case 'completed':
              return `O pedido #${activity.attributes.log.orderId} teve seu status alterado para Concluído.`
            default:
              return `O pedido #${activity.attributes.log.orderId} teve status alterado para ${activity.attributes.log.status}`
          }
        case 'invoice.created':
          return `A nota ${activity.attributes.log.code} foi adicionada com sucesso.`
        case 'invoice.deleted':
          return `A nota ${activity.attributes.log.code} foi cancelada pelo administrador.`
        case 'invoice.canceled':
          return `A nota ${activity.attributes.log.code} foi cancelada pelo administrador.`
        case 'invoice.updated_status':
          switch (activity.attributes.log.status) {
            case 'approved':
              return `A nota ${activity.attributes.log.code} teve status alterado para REABILITADA`
            case 'rejected':
              return `A nota ${activity.attributes.log.code} teve status alterado para REJEITADA`
            case 'pending':
              return `A nota ${activity.attributes.log.code} teve status alterado para PENDENTE`
            default:
              return `A nota ${activity.attributes.log.code} teve status alterado para ${activity.attributes.log.status}`
          }
        case 'user.updated_price_list':
          return `A sua tabela de preços foi atualizada para ${activity.attributes.log.priceListName}`
        case 'compensation.synced':
          return `Uma compensação de ${formatNumber(activity.attributes.log.amount / 100)} foi aplicada a você.`
        default:
          return activity.attributes.action
      }
    }
  }
}
</script>
